<template>
  <div v-if="user">
    <sb-loading v-if="archiver.loading" />
    <sb-page-header>
      <template v-slot:left>
        <h1>
          {{ user.fullName }}
          <small>Begeleider</small>
        </h1>
      </template>
      <template v-slot:right>
        <dropdown placement="bottom-end">
          <i-button type="primary" ghost>
            Acties
            <icon type="md-more" />
          </i-button>

          <dropdown-menu slot="list" style="width: 220px">
            <router-link :to="{ name: 'ResourceManagementCoachesCoachEdit' }">
              <sb-dropdown-button> Gegevens bewerken </sb-dropdown-button>
            </router-link>

            <router-link
              :to="{ name: 'ResourceManagementCoachesCoachEditPassword' }"
            >
              <sb-dropdown-button> Wachtwoord wijzigen </sb-dropdown-button>
            </router-link>

            <sb-dropdown-button
              v-if="['ADMIN', 'MANAGER'].includes($user.role)"
              style="color: red"
              @click.native="archiveUser"
            >
              Coach archiveren
            </sb-dropdown-button>
          </dropdown-menu>
        </dropdown>
      </template>
    </sb-page-header>

    <sb-tabbed-page v-if="user" :data="user" />
  </div>
</template>

<script>
import SbLoading from '@/components/SbLoading';
import SbPageHeader from '@/components/SbPageHeader';
import SbTabbedPage from '@/components/SbTabbedPage';
import GetUserById from '@/graphql/queries/GetUserById.gql';
import { UserArchiver } from '@/lib/user-archiver';

export default {
  name: 'OnlineStudent',

  components: {
    SbPageHeader,
    SbTabbedPage,
    SbLoading,
  },

  props: {
    coachId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      showAddUsersToSchoolModal: false,
      showRemoveUsersFromSchoolModal: false,
      usersToAddToSchool: null,
    };
  },

  computed: {
    parentId() {
      return null;
    },
  },

  created() {
    this.archiver = new UserArchiver(this)
      .setUserTypeLabel('coach')
      .on('success', this.$router.navigateBack);
  },

  methods: {
    async archiveUser() {
      await this.archiver.archiveUser(this.user.id);
    },

    addCoachToSchool() {
      this.usersToAddToSchool = [this.user.id];
      this.showAddUsersToSchoolModal = true;
    },

    removeCoachFromSchool() {
      this.usersToAddToSchool = [this.user.id];
      this.showRemoveUsersFromSchoolModal = true;
    },
  },

  apollo: {
    user: {
      query: GetUserById,

      variables() {
        return {
          id: this.coachId,
        };
      },

      update(data) {
        if (!data.getUserById) {
          this.$Modal.warning({
            title: 'Melding',
            content: 'Deze gebruiker is niet gevonden',
            onOk: () => {
              this.$router.navigateBack();
            },
          });
        }
        return data.getUserById;
      },
    },
  },
};
</script>

<style lang="scss"></style>
