<template>
  <div>
    <sb-table2
      :config="sessionsTableConfig"
      :data="sessions"
      :loading="$apollo.queries.tableData.loading"
      :with-navigation="false"
      @cell-click-name="viewStudent"
    >
      <template slot="column-name" slot-scope="{ content }">
        <span class="name">{{ content }}</span>
      </template>
    </sb-table2>
  </div>
</template>

<script>
import SingleCoachActivityTable from '@/components/SbCoachActivityTable/SingleCoachActivityTable.gql';
import { nonReactiveMembersMixin } from '@/mixins/nonReactiveMembersMixin';
import { TableConfig } from '@/components/SbTable2/TableConfig';
import { coachTypeMixin } from '@/lib/coach-type';
import SbTable2 from '@/components/SbTable2/SbTable2.vue';
import { DateTimeFilter } from '@/lib/gql-filters';

export default {
  components: {
    SbTable2,
  },
  mixins: [
    coachTypeMixin,
    nonReactiveMembersMixin((self) => {
      const sessionsTableConfig = new TableConfig({
        pageSizes: [500],
        columns: [
          {
            key: 'name',
            header: 'Leerling',
            width: 200,
            action: true,
            data: (row) => row.studentName,
          },
          {
            key: 'username',
            header: 'Gebruikersnaam',
            width: 200,
            data: (row) => row.username,
          },
          {
            key: 'updatedAt',
            header: 'Datum',
            sort: 'ascending',
            meta: { gqlFilter: DateTimeFilter },
            data: (row) => {
              if (row.updatedAt === '-') return '-';
              const date = new Date(row.updatedAt);
              return [
                date.toLocaleDateString(),
                date.toLocaleTimeString(),
              ].join(', ');
            },
          },
          {
            key: 'track',
            header: 'Leesniveau',
            data: (row) => row.readingLevel,
          },
          {
            key: 'problemFocus',
            header: 'Problematiek',
            data: (row) => row.problemFocus,
          },
          {
            key: 'cards',
            header: 'Kaarten',
            width: 300,
            data: (row) => row.cardTitle,
          },
          {
            key: 'status',
            header: 'Status',
            data: (row) => row.status,
          },
        ],
      });
      return { sessionsTableConfig };
    }),
  ],

  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  computed: {
    sessions() {
      return this.tableData ?? [];
    },
  },

  methods: {
    viewStudent({ id }) {
      const params = {
        resourceType: 'school',
        studentId: id,
        resourceId: this.$user.context.resourceId,
      };

      return this.$router.push({
        name: 'ResourceManagementStudentsStudent',
        params,
      });
    },
  },

  apollo: {
    tableData: {
      variables() {
        return {
          filter: {
            coachId: {
              equals: this.data.id,
            },
          },
          orderBy: { updatedAt: 'DESC' },
        };
      },

      update: (data) => {
        const rows = data?.getLatestSessionsPerStudent ?? [];
        if (!rows?.length) return rows;

        function getStatus(lastSession, finishedAt) {
          if (!lastSession) return '-';
          return finishedAt ? 'Voltooid' : 'Bezig';
        }

        const result = rows.map((node) => {
          const lastSession = node.lastSession;
          const fullName = node.fullName;
          const createdAt = lastSession?.createdAt ?? '-';
          const finishedAt = lastSession?.finishedAt ?? '-';
          const problemFocus =
            lastSession !== null
              ? lastSession?.problemFocus?.title ?? '-'
              : '-';

          // NO FIX FOR THIS
          const readingLevel =
            lastSession !== null
              ? lastSession?.cards?.[0]?.card?.level ?? '-'
              : '-';

          const cardTitle =
            lastSession !== null
              ? lastSession?.cards?.[0]?.card?.title ?? '-'
              : '-';
          const updatedAt =
            lastSession !== null ? lastSession?.updatedAt ?? '-' : '-';
          const status = getStatus(lastSession, finishedAt);

          return {
            id: node.id,
            studentName: fullName,
            username: node.username,
            problemFocus,
            readingLevel,
            cardTitle,
            createdAt,
            finishedAt,
            updatedAt,
            status,
          };
        });

        // Sort with sessions first, then without sessions
        const withSession = result
          .filter((row) => row.updatedAt !== '-')
          .sort((a, b) => b.updatedAt - a.updatedAt);
        const withoutSession = result.filter((row) => row.updatedAt === '-');
        return [...withSession, ...withoutSession];
      },

      query() {
        return SingleCoachActivityTable;
      },
    },
  },
};
</script>

<style scoped>
.username {
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
  display: inline-block;
}
</style>
