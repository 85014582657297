<template>
  <div>
    <sb-page-header>
      <template v-slot:left>
        <h1>Begeleiders</h1>
      </template>

      <template v-slot:right>
        <router-link
          v-if="
            ['ADMIN', 'MANAGER'].includes($user.role) && !$user.context.isAdmin
          "
          :to="{ name: 'ResourceManagementCoachAdd' }"
        >
          <i-button id="AddCoachButton" type="primary" ghost>
            Begeleider toevoegen
            <icon type="md-add" />
          </i-button>
        </router-link>
      </template>
    </sb-page-header>

    <sb-coaches-table :organisation-id="organisationId || resourceId" />
  </div>
</template>

<script>
import SbPageHeader from '@/components/SbPageHeader';
import SbCoachesTable from '@/components/SbCoachesTable';
import { mapActions } from 'vuex';

export default {
  components: {
    SbPageHeader,
    SbCoachesTable,
  },

  props: {
    organisationId: {
      type: String,
      default: null,
    },
    resourceId: {
      type: String,
      default: null,
    },
    courseGroupId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
    };
  },

  mounted() {
    const currentTourKey = 'MANAGER_COACH';
    this.enqueueTour(currentTourKey);
  },

  methods: {
    ...mapActions('onboarding', ['enqueueTour']),
  },
};
</script>

<style></style>
