<template>
  <div>
    <sb-spacer height="40" />

    <div style="display: flex; justify-content: flex-end">
      <i-button
        type="primary"
        @click="$router.push({ name: 'ResourceManagementCoachesCoachEdit' })"
      >
        Gegevens bewerken
      </i-button>
    </div>
    <div style="width: 800px">
      <sb-divider title="Persoonlijke informatie" />

      <sb-list>
        <sb-list-item label="id:" style="opacity: 0.3">
          {{ data.id }}
        </sb-list-item>
        <sb-list-item label="Naam:">
          {{ data.firstName }} {{ data.prefix || '' }} {{ data.lastName }}
        </sb-list-item>
        <sb-list-item label="Gebruikersnaam:">
          {{ data.username }}
        </sb-list-item>
        <sb-list-item label="Email:">
          <a :href="`mailto:${data.email}`" target="_blank">{{ data.email }}</a>
        </sb-list-item>
        <!-- <SbListItem
            label="Leerjaar:"
          >
            {{ data.educationGroup.name }}
          </SbListItem>
          <SbListItem label="Geboortedatum:">
            {{ formatDate(data.birthDate) }}
          </SbListItem>-->
      </sb-list>
      <sb-divider title="School" />
      <sb-list-item
        v-for="school in data.organisations"
        :key="`school-${school.id}`"
        :label="school.name"
      />

      <sb-divider v-if="data.coachingGroups.length" title="Cursusgroep(en)" />
      <sb-list-item
        v-for="courseGroups in data.coachingGroups"
        :key="`coursegroup-${courseGroups.id}`"
        :label="courseGroups.name"
      />

      <sb-spacer height="40" />
    </div>
  </div>
</template>

<script>
import SbDivider from '@/components/SbDivider';
import SbList from '@/components/SbList';
import SbListItem from '@/components/SbListItem';

import { formatDate } from '@/lib/date-helper.js';

export default {
  name: 'StudentData',
  components: {
    SbDivider,
    SbList,
    SbListItem,
  },

  props: {
    data: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  data() {
    return {
      diplomaGenerating: false,
      formatDate,
    };
  },

  async mounted() {
    console.log('data', this.data);
  },

  methods: {},
};
</script>

<style></style>
