<template>
  <div class="coach-activity">
    <sb-divider title="Laatste activiteit per leerling" />
    <sb-single-coach-activity-table :data="data" />
  </div>
</template>

<script>

import SbSingleCoachActivityTable from '@/components/SbCoachActivityTable/SbSingleCoachActivityTable.vue';

export default {
  components: {
    SbSingleCoachActivityTable
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    organisationId: {
      type: String,
      default: null,
    },
    resourceId: {
      type: String,
      default: null,
    },
  },
};
</script>

<style>
.coach-activity {
  margin-bottom: 20px;
}
</style>
