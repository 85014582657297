<template>
  <div>
    <sb-page-header>
      <template v-slot:left>
        <h1>Begeleider {{ editMode ? 'aanpassen' : 'toevoegen' }}</h1>
      </template>
    </sb-page-header>

    <sb-user-form
      ref="form"
      :default-data="data"
      default-mode="edit"
      :role="Role.COACH"
      :organisation-id="organisationId"
    >
      <template v-slot:default="{ formData }">
        <sb-create-group-modal
          v-model="showCreateGroupModal"
          @created="handleCreated"
        />
        <sb-divider title="Schoolinformatie" />

        <form-item
          id="TypeCoachSwitch"
          label="Type begeleider"
          prop="coachType"
        >
          <radio-group v-model="formData.coachType">
            <radio
              v-for="coachType in CoachType"
              :key="coachType"
              :label="coachType"
            >
              {{ getCoachTypeLabel(coachType) }}
            </radio>
          </radio-group>
        </form-item>
        <sb-course-group-select
          :selected-group-id="
            formData.coachingGroupsIds[0] ? formData.coachingGroupsIds[0] : null
          "
          @open="() => (showCreateGroupModal = true)"
          @onchange="
            (courseGroupId) => (formData.coachingGroupsIds = [courseGroupId])
          "
        />
      </template>
    </sb-user-form>
  </div>
</template>

<script>
import SbPageHeader from '@/components/SbPageHeader';
import SbCourseGroupSelect from '@/components/SbCourseGroupSelect/SbCourseGroupSelect.vue';
import SbUserForm from '@/components/SbUserForm/SbUserForm.vue';
import SbCreateGroupModal from '@/components/SbUserForm/SbCreateGroupModal.vue';
import { coachTypeMixin } from '@/lib/coach-type';
import { roleMixin } from '@/lib/role';
import { getResourceId } from '@/lib/user-context-manager';
import { mapActions } from 'vuex';

export default {
  components: {
    SbPageHeader,
    SbUserForm,
    SbCourseGroupSelect,
    SbCreateGroupModal,
  },

  mixins: [roleMixin, coachTypeMixin],

  props: {
    data: {
      type: Object,
      default: null,
    },
  },

  data: () => ({ showCreateGroupModal: false }),

  computed: {
    editMode() {
      return !!this.data;
    },

    organisationId() {
      return (
        this.$route.query.organisationId ||
        this.$route.params.resourceId ||
        getResourceId()
      );
    },
  },

  mounted() {
    const currentTourKey = 'MANAGER_BEGELEIDER_ADD';
    this.enqueueTour(currentTourKey);
  },
  methods: {
    ...mapActions('onboarding', ['enqueueTour']),
    async handleCreated(createdGroup) {
      this.$refs.form.formData.coachingGroupsIds.splice(0, 1, createdGroup.id);
    },
  },
};
</script>
